<template>
  <div class="">
    <div class="container-fluid">
      <div class="hero-image-ab row mb-5">
        <div
          class="bg-dense-ab justify-content-center align-items-center d-flex"
        >
          <div>
            <div class="big-text-header-ab">
              <span>{{ $t("message.content28") }}</span>
            </div>
            <div class="text-header-ab center-respone-bg">
              <p>{{ $t("message.content29") }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="center-respone" style="">
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="6" class="p-0">
            <img class="img-respone" src="@/assets/image-about/2.jpg"
          /></b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start align-items-center custop-swap-top"
          >
            <div class="text-left">
              <div class="big-text-ab">
                <p>{{ $t("message.content30") }}</p>
              </div>
              <div class="middle-text-ab">
                <p>
                  {{ $t("message.content36") }}
                </p>
                <p>{{ $t("message.content101") }}</p>
                <p>{{ $t("message.content102") }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="center-respone pt-5" style="">
        <b-row class="justify-content-md-center">
          <b-col cols="12" md="4" class="justify-content-md-center">
            <img
              class="img-respone"
              src="@/assets/image-service/service17.jpg"
            />
          </b-col>
          <b-col cols="12" md="4" class="justify-content-md-center">
            <img
              class="img-respone"
              src="@/assets/image-service/service18.jpg"
            />
          </b-col>
          <b-col cols="12" md="4" class="justify-content-md-center">
            <img
              class="img-respone"
              src="@/assets/image-service/service19.jpg"
            />
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="" style="">
      <div class="d-flex justify-content-center align-items-center my-5">
        <div class="big-font-commit-ab">
          <span>{{ $t("message.content31") }}</span>
        </div>
      </div>
      <div class="lr-card-about">
        <!-- <div> -->
        <commitment />
      </div>
    </div>

    <div class="p-0 center-respone">
      <div class="d-flex justify-content-center align-items-center my-5">
        <div class="big-font-whybella">
          <span>{{ $t("message.content32") }}</span>
        </div>
      </div>
      <whybella />
    </div>

    <div>
      <div class="d-flex justify-content-center align-items-center pt-5">
        <div class="big-font-main mx-5">
          <span>{{ $t("message.content110") }}</span>
          <!-- <span>{{ $t("message.content33") }}</span> -->
        </div>
      </div>

      <!-- timeline -->
      <div class="wrapper">
        <section class="block">
          <div class="each-year">
            <div class="title">2009</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">March</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">September</div>
                </div>
                <br />
                HKTDC Hong Kong International Jewellery Show.
              </div>
            </div>
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2010</div>
            
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                <div class="timeline-month text-center">March</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-JAAaustralia.jpg"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">August</div>
                </div>
                <br />
                 JAA international jewelry fair australia.
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">September</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>

          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2011</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">March</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">September</div>
                </div>
                <br />
                  HKTDC Hong Kong International Jewellery Show.
              </div>
            </div>
            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2012</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">Febuary</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">September</div>
                </div>
                <br />
                  HKTDC Hong Kong International Jewellery Show.
              </div>
            </div>
            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2013</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">Febuary</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>
            
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                <div class="timeline-month text-center">May</div>
                </div>
                <br />
                  HKTDC Hong Kong International Jewellery Show.
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">September</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>

          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2014</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">March</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-jck.png"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">May</div>
                </div>
                <br />
                  JCK Las Vegas.
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">September</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>
            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2015</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">March</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-jck.png"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">May</div>
                </div>
                <br />
                  JCK Las Vegas.
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">September</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>
            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2016</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">March</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-jck.png"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">May</div>
                </div>
                <br />
                  JCK Las Vegas.
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">September</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  HKTDC Hong Kong International Jewellery Show.
                </div>
              </div>
            </div>
            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2017</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">May</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-jck.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  JCK Las Vegas.
                </div>
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">September</div>
                </div>
                <br />
                  HKTDC Hong Kong International Jewellery Show.
              </div>
            </div>
            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2018</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">June</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-jck.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  JCK Las Vegas.
                </div>
              </div>
            </div>

            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-HKTDC.png"
                    alt="submit"
                  />
                  <div class="timeline-month text-center">September</div>
                </div>
                <br />
                  HKTDC Hong Kong International Jewellery Show.
              </div>
            </div>
            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2019</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center px-2">June</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-jck.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  JCK Las Vegas.
                </div>
              </div>
            </div>            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2022</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">June</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-jck.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  JCK Las Vegas.
                </div>
              </div>
            </div>            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title">2023</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <div class="timeline-month text-center">Jan</div>
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-Vicenzaoro.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end">
                  Vicenzaoro - the Jewelry Boutique Show.
                </div>
              </div>
            </div>            
          </div>
        </section>

        <section class="block">
          <div class="each-year">
            <div class="title ">soon</div>
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <!-- <div class="timeline-month text-center">June</div> -->
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-jck.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="text-end soon">
                  June 2 - 5, 2023 - JCK Las Vegas.
                </div>
              </div>
            </div>

            
            <div class="each-event">
              <div class="event-description">
                <div class="d-flex justify-content-center">
                  <!-- <div class="timeline-month text-center">June</div> -->
                  <img
                    class="img-timeline-style"
                    src="@/assets/image-about/timline-Vicenzaoro.png"
                    alt="submit"
                  />
                </div>
                <br />
                <div class="soon">
                  Jan 2024 - Vicenzaoro - the Jewelry Boutique Show.
                </div>
              </div>
            </div>

          </div>
        </section>


      </div>

      
      <div class="d-flex justify-content-center align-items-center pt-5">
        <div class="big-font-main mx-5">
          <span>{{ $t("message.content33") }}</span>
        </div>
      </div>


      <div class=" ">
        <div class="d-flex justify-content-center">
          <div class="mx-2 my-5">
            <img
              class="img-style"
              src="@/assets/image-about/Thai_Gems_Jewelry_Traders_Association.png"
              alt="submit"
            />
          </div>

          <div class="mx-2 my-5">
            <img
              class="img-style"
              src="@/assets/image-about/Thai_Silver_Exporters_Association.png"
              alt="submit"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <gettouch />
    </div>
  </div>
</template>

<script>
import Commitment from "@/components/Card_commitment.vue";
import Whybella from "@/components/Whybella.vue";
import Gettouch from "@/components/Gettouch.vue";
export default {
  components: {
    commitment: Commitment,
    whybella: Whybella,
    gettouch: Gettouch,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
@import "@/assets/scss/home";
@import "@/assets/scss/about";
</style>

<style>
.design-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 0;
  font-family: Jost;
}

.design {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline {
  width: 80%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.timeline-content {
  padding: 20px;
  color: #1f1f1f;
  padding: 1.75rem;
  transition: 0.4s ease;
  overflow-wrap: break-word !important;
  margin: 1rem;
  margin-bottom: 20px;
  border-radius: 6px;
}

.timeline-component {
  margin: 0px 20px 20px 20px;
}

@media screen and (min-width: 768px) {
  .timeline {
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
  }
  .timeline-middle {
    position: relative;
    background-image: linear-gradient(45deg, #f27121, #e94057, #8a2387);
    width: 3px;
    height: 100%;
  }
  .main-middle {
    opacity: 0;
  }
  .timeline-circle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #e94057;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
</style>


<style lang="scss" scoped>
.wrapper {
  // background-color: #42A5F5;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  font: normal 16px/26px Open Sans Condensed, sans-serif;
  color: #fff;
  // padding: 100px 20px 200px;
  padding: 0px 20px 0px;
  box-sizing: border-box;
}

.block {
  z-index: 1;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: calc(100% - 40px);
    width: 1px;
    background-color: #515168;
    left: 50%;
    margin-top: 90px;
  }
}

.title {
  font: bold 32px/42px Open Sans Condensed, sans-serif;
  text-align: center;
  padding: 20px;
  transition: all 0.8s ease-in-out;
  color: #000000;
}

.each-event {
  // background-color: #f4f4f4;
  color: #777;
  padding: 15px;
  margin: 60px 20px;
  width: 300px;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  &:before {
    content: "";
    position: absolute;
    top: calc(50% - 8px);
    left: -27px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    border: 5px red solid;
    box-shadow: 0 4px 20px -4px rgba(64, 64, 64, 0.8);
  }
  &:nth-child(odd) {
    margin-left: calc(50% + 20px);
  }
  &:nth-child(even) {
    margin-left: calc(50% - 321px);
    &:before {
      left: calc(100% + 14px);
    }
  }
}

.title.non-focus {
  font-size: 24px;
  line-height: 34px;
  transition: all 0.8s ease-in-out;
}

.each-event.non-focus {
  color: #d2d2d2;
  background: #e6e6e6;
  margin-top: 90px;
  transform: rotateZ(45deg);
  transition: all 0.8s ease-in-out;
  &:before {
    opacity: 0;
    background-color: #aaa;
  }
}

.img-timeline-style {
  width: 150px;
  height: auto;
  padding-bottom: 0.5rem;
  padding-right: 10px;
  padding-left: 10px;
}

.timeline-month {
  width: auto;
  height: 25px;
  border-radius: 5px;
  color: #f73838;
  background-color: #f4f4f4;
  font-size: 12px;
  font-weight: bold;
  padding-right: 10px;
  padding-left: 10px;
}

.soon {
  font-weight: bold;
  color: #f73838;
}

</style>