<template>
  <!-- <div class="row-fluid"> -->
  <div class="container-fluid">
    <div class="row justify-content-md-center pd-none">
      <div class="col-6 col-md-2 pd-none" style="background:#f9f9f9;">
        <img class="img-respone" src="@/assets/image-about/3.jpg" />
      </div>
      <div class="col-6 col-md-4 pd-none" style="background:#f9f9f9;">
        <img class="img-respone" src="@/assets/image-about/4.jpg" />
      </div>
      <div
        class="col-12 col-md-6 d-flex justify-content-center align-items-center bg-box-dack "
      >
        <div class="auto-rl-ten-ab py-4">
          <div class="big-text-wb-white-top">
            <p>{{ $t("message.content34") }}</p>
          </div>
          <div class="middle-text-wb-white-top ">
            <div class="d-none-quality">
              <p>
                {{ $t("message.content35") }}
              </p>
              <p>
                {{ $t("message.content103") }}
              </p>
            </div>
            <div class="d-none-whybella">
              <p v-if="isLoggedone">
                {{ $t("message.content35") }}
              </p>
              <p v-if="isLoggedtwo">
                {{ $t("message.content103") }}
              </p>
            </div>
          </div>
          <div class=" pb-3 d-none-whybella ">
            <div  @click="clikfortrueone()">
              <img
                v-if="isLoggedone"
                type="button"
                src="@/assets/icon/Page_W.png"
                style="height: 5px;width: 17px;"
              />
              <img
                v-else
                type="button"
                src="@/assets/icon/Page_B.png"
                style="height: 5px;width: 17px;"
              />
            </div>

            <div class="px-2" @click="clikfortruetwo()">
              <img
                v-if="isLoggedtwo"
                type="button"
                src="@/assets/icon/Page_W.png"
                style="height: 5px;width: 17px;"
              />
              <img
                v-else
                type="button"
                src="@/assets/icon/Page_B.png"
                style="height: 5px; width: 17px;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-center p-0">
      <div
        class="col-12 col-md-6 d-flex justify-content-center align-items-center  bg-box-gray "
      >
        <div class="auto-rl-ten-ab py-4">
          <div class="big-text-wb-black">
            <p>
              {{ $t("message.content37") }}
            </p>
          </div>
          <div class="middle-text-wb-black">
            <p>
              {{ $t("message.content38") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 p-0 custop-swap-toptwo">
        <img class="img-respone" src="@/assets/image-about/6.jpg" />
      </div>
      <div class="col-6 col-md-2 p-0 custop-swap-toptwo">
        <img class="img-respone" src="@/assets/image-about/5.jpg" />
      </div>
    </div>

    <div class="row justify-content-md-center p-0">
      <div class="col-6 col-md-2 p-0">
        <img class="img-respone" src="@/assets/image-about/7.jpg" />
      </div>
      <div class="col-6 col-md-4 p-0">
        <img class="img-respone" src="@/assets/image-about/8.jpg" />
      </div>
      <div
        class="col-12 col-md-6  d-flex justify-content-center align-items-center  bg-box-dack"
      >
        <div class="auto-rl-ten-ab py-4">
          <div class="big-text-wb-white">
            <p>
              {{ $t("message.content39") }}
            </p>
          </div>

          <div class="middle-text-wb-white">
            <p>
              {{ $t("message.content40") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery'
export default {
  components: {},
  methods: {
    clikfortrueone() {
      if (this.isLoggedone === false) {
        this.isLoggedtwo = false;
        this.isLoggedone = true;
      }
      console.log("test1");
    },
    clikfortruetwo() {
      if (this.isLoggedtwo === false) {
        this.isLoggedtwo = true;
        this.isLoggedone = false;
      }
      console.log("test2");
    },
  },
  data() {
    return {
      isLoggedone: true,
      isLoggedtwo: false,
    };
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/scss/home";
@import "@/assets/scss/whybella";
@import "@/assets/scss/main";
</style>
